import React from 'react';
import { Box, Typography } from '@mui/material';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';

import { renderTextField, Switcher } from '../../../../components/ReduxFormFields';
import { required, validateFloatWithThreeDecimal, parseFloatNumbersWithOneDigitAfterPoint } from '../../../validation';

import i18n from '../../../../i18n';
import { StepsEditFormBySeason } from './StepsEditFormBySeason';
import { Expander, Tooltip } from '../../../../components';

export const DoubleTariff = ({ formValues = {}, unit = '', skipValidation = false }) => (
  <>
    <Typography
      sx={{ my: 2, textTransform: 'capitalize', color: 'secondary.dark' }}
      variant="body2"
    >
      {i18n.t('price')}
      {`: (${unit})`}
    </Typography>
    <Box
      sx={{ display: 'flex', justifyContent: 'space-between', columnGap: '35px', mb: 1 }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          columnGap: '15px',
          flexBasis: '50%'
        }}
      >
        <Typography
          sx={{
            textTransform: 'capitalize',
            color: 'secondary.lowTariff',
            minWidth: 'max-content',
            flexBasis: '30%'
          }}
          variant="body2"
        >
          {`${i18n.t('lowTariff')}`}
        </Typography>
        <Field
          InputProps={{
            disableUnderline: true,
            sx: { backgroundColor: 'primary.dark', borderRadius: '4px', p: '4px 20px' }
          }}
          variant="standard"
          sx={{ color: 'secondary', fontSize: '14px', flexBasis: '70%' }}
          component={renderTextField}
          isFloat
          name="lowTariff"
          parse={parseFloatNumbersWithOneDigitAfterPoint}
          validate={skipValidation ? [] : [required, validateFloatWithThreeDecimal]}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          columnGap: '15px',
          flexBasis: '50%'
        }}
      >
        <Typography
          sx={{
            textTransform: 'capitalize',
            color: 'secondary.highTariff',
            minWidth: 'max-content',
            flexBasis: '30%'
          }}
          variant="body2"
        >
          {`${i18n.t('hightTariff')}`}
        </Typography>
        <Field
          InputProps={{
            disableUnderline: true,
            sx: { backgroundColor: 'primary.dark', borderRadius: '4px', p: '4px 20px' }
          }}
          variant="standard"
          sx={{ color: 'secondary', fontSize: '14px', flexBasis: '70%' }}
          component={renderTextField}
          isFloat
          name="highTariff"
          parse={parseFloatNumbersWithOneDigitAfterPoint}
          validate={skipValidation ? [] : [required, validateFloatWithThreeDecimal]}
        />
      </Box>
    </Box>
    <StepsEditFormBySeason formValues={formValues} field="commonSeasons" />
    <Box sx={{ display: 'flex', columnGap: 2, alignItems: 'center', my: 3 }}>
      <Field
        name="isWinterTimeEnabled"
        component={Switcher}
        props={{
          margin: 0,
          width: 50,
          height: 25,
          onColor: '#ffb008',
          className: 'switcher m-0'
        }}
      />
      <Typography sx={{ color: 'secondary.main' }} variant="h4">
        {`${i18n.t('winterTimeTariff')}`}
        {' '}
        <Tooltip title={i18n.t('winterTimeTariffTooltip')} placement="right" />
      </Typography>
    </Box>
    <Expander expanded={Boolean(formValues.isWinterTimeEnabled)}>
      <Typography sx={{ color: 'secondary.dark', mt: 2, mb: 1 }} variant="body1">
        {`${i18n.t('wintertimeTariffDescription')}`}
      </Typography>
      <StepsEditFormBySeason formValues={formValues} field="winterSeason" />
    </Expander>
  </>
);

DoubleTariff.propTypes = {
  formValues: PropTypes.instanceOf(Object),
  unit: PropTypes.string,
  skipValidation: PropTypes.bool
};
