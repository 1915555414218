import React from 'react';

import { Field } from 'redux-form';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { renderTextField } from '../../../../components/ReduxFormFields';
import { required, validateFloatWithThreeDecimal, parseFloatNumbersWithOneDigitAfterPoint } from '../../../validation';

import i18n from '../../../../i18n';

export const SingleTariff = ({ unit = '', skipValidation = false }) => (
  <Box>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        my: 1
      }}
    >
      <Box sx={{ display: 'flex' }}>
        <Typography
          sx={{
            textTransform: 'capitalize',
            color: 'secondary.main',
            minWidth: 'max-content',
            pr: 0.5
          }}
          variant="body2"
        >
          {`${i18n.t('singleTariff')}`}
        </Typography>
        <Typography
          sx={{
            color: 'secondary.main',
            minWidth: 'max-content'
          }}
          variant="body2"
        >
          {`(${unit})`}
        </Typography>
      </Box>
      <Field
        InputProps={{
          disableUnderline: true,
          sx: { backgroundColor: 'primary.dark', borderRadius: '4px', p: '4px 20px', maxWidth: '150px' }
        }}
        variant="standard"
        sx={{ color: 'secondary', fontSize: '14px', flexBasis: '70%' }}
        component={renderTextField}
        isFloat
        name="singleTariff"
        parse={parseFloatNumbersWithOneDigitAfterPoint}
        validate={skipValidation ? [] : [required, validateFloatWithThreeDecimal]}

      />
    </Box>
    <Typography sx={{ my: 2, color: 'secondary.dark' }} variant="body1">
      {i18n.t('singleTariffDescription')}
    </Typography>
  </Box>
);

SingleTariff.propTypes = {
  unit: PropTypes.string,
  skipValidation: PropTypes.bool
};
