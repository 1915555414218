import React from 'react';
import { Box, Typography } from '@mui/material';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';

import { required, validateFloatWithThreeDecimal, parseFloatNumbersWithOneDigitAfterPoint } from '../../../validation';
import Select from '../../../DevicesTable/components/Select';

import { renderTextField } from '../../../../components/ReduxFormFields';

import { Expander } from '../../../../components';

import i18n from '../../../../i18n';

import { APP_ID, HELION_APP_ID, SOLAR_MANAGER_APP_ID } from '../../../../config';
import { directMarketing } from '../../constants';

export const HelionExportTariffSection = ({ user = {}, myRoleType = '', formValues = {}, unit = '' }) => {
  const { directMarketing: directMarketingFormValue } = formValues;

  const isHelionUser = user?.company?.name.includes('Helion');
  const isRenderDirectMarketingField = (APP_ID === HELION_APP_ID && !['end_user', 'sub_user'].includes(myRoleType)) || (APP_ID === SOLAR_MANAGER_APP_ID && ['root', 'solar_admin'].includes(myRoleType));
  const directMarketingOptions = directMarketing.map((option) => ({ label: i18n.t(option.label), value: option.value }));

  if (!isHelionUser) {
    return null;
  }

  return (
    <>
      {isRenderDirectMarketingField && (
        <Expander expanded>
          <Typography sx={{ mt: 2, color: 'secondary.dark' }} variant="body2">
            {i18n.t('directMarketing')}
          </Typography>
          <Field
            name="directMarketing"
            component={Select}
            className="m-input"
            options={directMarketingOptions}
            defaultValue={directMarketing}
          />
        </Expander>
      )}

      {isRenderDirectMarketingField && directMarketingFormValue === 0 && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            my: 1,
            mt: 3
          }}
        >
          <Box sx={{ display: 'flex' }}>
            <Typography
              sx={{
                textTransform: 'capitalize',
                color: 'secondary.main',
                minWidth: 'max-content',
                pr: 0.5
              }}
              variant="body2"
            >
              {`${i18n.t('exportPrice')}`}
            </Typography>
            <Typography
              sx={{
                color: 'secondary.main',
                minWidth: 'max-content'
              }}
              variant="body2"
            >
              {`(${unit})`}
            </Typography>
          </Box>
          <Field
            InputProps={{
              disableUnderline: true,
              sx: { backgroundColor: 'primary.dark', borderRadius: '4px', p: '4px 20px', maxWidth: '150px' }
            }}
            variant="standard"
            sx={{ color: 'secondary', fontSize: '14px', flexBasis: '70%' }}
            component={renderTextField}
            isFloat
            name="exportStandardTariff"
            parse={parseFloatNumbersWithOneDigitAfterPoint}
            validate={[required, validateFloatWithThreeDecimal]}
          />
        </Box>
      )}
    </>
  );
};

HelionExportTariffSection.propTypes = {
  user: PropTypes.instanceOf(Object),
  myRoleType: PropTypes.string,
  formValues: PropTypes.instanceOf(Object),
  unit: PropTypes.string
};
